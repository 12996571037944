.headerSection {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5; /* Light grey background */
  border-bottom: 1px solid #e0e0e0; /* Bottom border for separation */
}

.subHeader {
  margin-left: 10px; /* Space between back button and subheader */
  font-weight: normal;
}

.tablesContainer {
  display: flex;        /* Align items in a row */
  overflow-x: auto;     /* Enable horizontal scrolling */
}

/* Apply this class to each EditableTable component */
.editableTable {
  flex: 0 0 auto;       /* Don't grow, don't shrink, and be automatically sized */
}

