.navbar {
  background-color: #FFFFFF;
  color: #000000;
}

.publicButton {
  text-transform: none;
  border-radius: 4px;
  padding: 6px 16px;
  border: 1px solid;
  margin-right: 16px;
  height: 36px;
}

.signUpButton {
  text-transform: none;
  border-radius: 4px;
  padding: 6px 16px;
  background-color: #4285F4;
  color: #FFFFFF;
  height: 36px;
  box-shadow: none;
}

.signUpLink {
  text-decoration: none;
}

