.container {
  padding: 20px;
  background-color: #fff;
}

.header {
  margin-bottom: 10px;
}

.title {
  font-size: 28px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #555;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th, .td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.th {
  background-color: #f2f2f2;
}

.tr:nth-child(even) {
  background-color: #f9f9f9;
}

.required {
  color: #e74c3c;
}

.optional {
  color: #2ecc71;
}

.link {
  color: #0073e6;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.urlStructureContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.urlStructureText {
  font-weight: bold;
  margin-right: 8px;
}

.colon {
  margin: 0 4px;
}

.caption {
  padding-top: 30px;
  color: #808080; /* This is a medium gray */
}