.formComponent {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.submitButton {
  background-color: #9B51E0 !important;
  color: #fff !important;
  transition: background-color 0.3s;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.submitButton:hover {
  background-color: #8742c6 !important; /* A slightly darker shade of #9B51E0 */
}

.submitButton:active {
  background-color: #6e33a1 !important; /* An even darker shade of #9B51E0 */
}

.utmViewerWrapper {
  background-color: #f5f5f5; /* light grey color */
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0px;
  margin-left: -32px; /* assuming the padding is 16px, adjust if needed */
  margin-right: -32px; /* assuming the padding is 16px, adjust if needed */
}