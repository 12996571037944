.tableContainer {
  height: 400px;
  background-color: #fff;
  border: 1px solid #dfe1e5;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Roboto', sans-serif;
  padding-left: 20px;
}

.dataGrid {
  width: 400px;
  background-color: #ffffff;
  --unstable_DataGrid-radius: 0;
}

.dataGrid .MuiDataGrid-columnHeaders {
  background-color: #f1f3f4;
  color: #202124;
  font-size: 14px;
  font-weight: 500;

}

.dataGrid .MuiDataGrid-columnHeaderTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.dataGrid .MuiDataGrid-cell {
  background-color: #fff; /* White background for cells */
  border-bottom: 1px solid #e0e0e0;
  color: #202124;
  font-size: 14px;
  line-height: 24px;
}

.dataGrid .MuiDataGrid-row:hover {
  background-color: #f5f5f5;
}

.dataGrid .MuiDataGrid-cell:focus,
.dataGrid .MuiDataGrid-cell:focus-within {
  outline: 2px solid #4285f4;
  outline-offset: -1px;
}

.dataGrid .MuiDataGrid-cell--editable {
  cursor: pointer;
}

.dataGrid .MuiDataGrid-cell--editing {
  background-color: #fff;
  input {
    font-size: 14px;
  }
}

.dataGrid .row-with-dependencies {
  background-color: #e6f4ea;
}

.dataGrid .MuiDataGrid-root {
  border-radius: 0; /* Remove rounded borders */
}
